import { DEAL_TIMESTAMPS_FRAGMENT } from '../../fragments/deal';
import { PAGE_INFO_FRAGMENT } from '../../offers/queries/shared';
import { FACETS_FRAGMENT } from './shared';

export const FEDERATED_SEARCH_RETURN_OFFERS = `
  fragment FederatedSearchReturnOffersFragment on FederatedSearchV1ReturnOffers {
    searchId
    context
    edges {
      id
      discountFixed
      discountPercentage
      freeShipping
      image {
        id
        url(height: 238)
      }
      price
      sourceUrl
      status
      temperature
      kind
      isTracked
      couponCode
      commentCount
      timestamps {
        ...DealTimestampsFragment
      }
      title
      store {
        name
        slug
        url
        image {
          id
          url(height: 238)
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
    facets {
      ...FacetsFragment
    }
    total
  }
`;

export const FEDERATED_SEARCH_RETURN_PRODUCTS = `
  fragment FederatedSearchReturnProductsFragment on FederatedSearchV1ReturnProducts {
    searchId
    indexSource
    context
    edges {
      id
      title
      categoryId
      image {
        id
        url
      }
      indexSource
      currentMinPrice
      totalStoresCount
      nominalModel
      minPriceStore {
        name
      }
      rangePercentageMessage {
        status
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
    facets {
      ...FacetsFragment
    }
    total
  }
`;

export const FEDERATED_SEARCH_RETURN_STORES = `
  fragment FederatedSearchReturnStoresFragment on FederatedSearchV1ReturnStores {
    searchId
    context
    edges {
      id
      name
      slug
      couponsCount
      offersCount
      productsCount
      image {
        id
        url(width: 64)
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    total
  }
`;

export const FEDERATED_SEARCH_RETURN_SUGGESTIONS = `
  fragment FederatedSearchReturnSuggestionsFragment on FederatedSearchV1ReturnSuggestions {
    context
    edges {
      suggestion
    }
  }
`;

export const FEDERATED_SEARCH_QUERY = `
  query FederatedSearch($input: [federatedSearchV1Input!]!) {
    public {
      federatedSearchV1(input: $input) {
        rules {
          mainContext
        }
        searches {
          ...FederatedSearchReturnOffersFragment
          ...FederatedSearchReturnProductsFragment
          ...FederatedSearchReturnStoresFragment
          ...FederatedSearchReturnSuggestionsFragment
        }
      }
    }
  }
  ${FACETS_FRAGMENT}
  ${FEDERATED_SEARCH_RETURN_OFFERS}
  ${FEDERATED_SEARCH_RETURN_PRODUCTS}
  ${FEDERATED_SEARCH_RETURN_STORES}
  ${FEDERATED_SEARCH_RETURN_SUGGESTIONS}
  ${DEAL_TIMESTAMPS_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;
